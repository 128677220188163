<template>
  <b-card title="Pengguna">
    <div class="row">
      <div class="col-sm-8">
        <div class="float-left">
          <!-- <b-button-group>
            <b-dropdown variant="primary" right split text="Aksi" v-if="$can('add', 'users') || $can('view', 'users')">
              <b-dropdown-item @click="add()" v-if="$can('add', 'users')" :disabled="isLoading">
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  /><span>Tambah</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-button-group> -->
          <b-button size="md" variant="primary" @click="add()"  v-if="$can('add', 'users')" class="mr-1">
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            /><span>Tambah</span>
          </b-button>
        </div>

        <div class="float-left user-filter">
          <v-select
            v-model="serverParams.role"
            :options="filterValues"
            :reduce="val => val.value"
            :clearable="false"
            input-id="users"
            placeholder="Filter role"
            @input="onFilterChange"
          />
        </div>

        <div class="ml-1 float-left user-filter">
          <v-select
            v-model="serverParams.verified_status"
            :options="filterVerifiedValues"
            :reduce="val => val.value"
            :clearable="false"
            input-id="users"
            placeholder="Filter status verifikasi"
            @input="onVerifiedFilterChange"
          />
        </div>

      </div>
      <div class="col-sm-4 pt-1 pt-md-0 pb-md-0">
        <div class="custom-search float-right">
          <b-form-group>
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="serverParams.search"
                placeholder="Ketik kata kunci disini..."
                name="text"
                class="d-inline-block"
              />
              <b-button size="md" variant="" @click="onSearchChange" class="ml-1">
                Cari
              </b-button>
            </div>
          </b-form-group>
        </div>

      </div>
    </div>

    <!-- table -->
    <div v-if="!rows || isLoadingTable" class="text-center">
        <b-spinner label="Tunggu..."></b-spinner>
    </div>
    <div v-if="isLoadingExcel" class="text-center">
        <center><b>Downloading all users data. Please wait and do not refresh or close the page. It will took some times to download all users data.</b></center>
    </div>
    <vue-good-table
      v-if="rows && !isLoadingTable"
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :totalRows="totalRecords"
      :columns="columns"
      :rows="rows"
      :sort-options="{
        enabled: true,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Row number -->
        <span
          v-if="props.column.field === 'row_number'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ (serverParams.page == 1) ? (props.row.originalIndex + 1) : ((props.row.originalIndex + 1) + ((serverParams.page - 1) * serverParams.perPage)) }}</span>
        </span>

        <!-- Column: First Name -->
        <span
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Email -->
        <span
          v-if="props.column.field === 'email'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: No. Tel -->
        <span
          v-if="props.column.field === 'phone'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Address -->
        <span
          v-if="props.column.field === 'address'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Gereja -->
        <span
          v-if="props.column.field === 'church_detail'"
          class="text-nowrap"
        >
          {{ (props.row.church) ? "GPIB " + props.row.church.name + " - " + props.row.church.mupel.mupel : props.row.church_name  }}
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.email_verified_at)" v-if="!showToggleVerification">
            {{ (props.row.email_verified_at) ? 'Diverifikasi' : 'Belum Diverifikasi'  }}
          </b-badge>
          <div v-if="showToggleVerification">
            <b-form-checkbox v-model="props.row.is_verified" @input="onVerificationToggle(props.row.is_verified, props.row.id)" :disabled="isLoadingForm" switch>
              Verifikasi
            </b-form-checkbox>
          </div>
        </span>

        <!-- Column: Role -->
        <span v-else-if="props.column.field === 'role'">
          <b-badge :variant="roleVariant(props.row.roles)" v-if="props.row.roles !== undefined && props.row.roles.length > 0">
            {{ props.row.roles[0].name }}
          </b-badge>
        </span>

        <!-- Column: Created at -->
        <span
          v-if="props.column.field === 'created_at'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Updated at -->
        <span
          v-if="props.column.field === 'updated_at'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="viewPage(props.row.id)" v-if="$can('view', 'users')">
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>Lihat</span>
              </b-dropdown-item>
              <b-dropdown-item @click="editPage(props.row.id)" v-if="$can('edit', 'users')">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Ubah</span>
              </b-dropdown-item>
              <b-dropdown-item @click="removeModal(props.row.id)" v-if="$can('delete', 'users')">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Hapus</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Data 1 hingga
            </span>
            <b-form-select
              v-model="serverParams.perPage"
              :options="['3','5','10', '25', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> dari {{ props.total }} </span>
          </div>
          <div>
            <b-pagination
              v-model="serverParams.page"
              :value="1"
              :total-rows="totalRecords"
              :per-page="serverParams.perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage: value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  <!-- Modals -->
  <b-modal
      id="modal-form"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showImportModal"
    >
      <template #modal-header="{}">
        <h5>Import {{ pageName }}</h5>
      </template>

      <div v-if="isLoadingForm || isLoading || isLoadingTable" class="text-center">
          <b-spinner label="Tunggu..."></b-spinner>
      </div>

      <b-alert variant="success" show v-if="importModal.successMessage !== null" class="p-1 mb-1">{{ importModal.successMessage }}</b-alert>
      
      <b-alert variant="danger" show v-if="importModal.errorMessages !== null && importModal.errorMessages.length > 0" class="p-1 mb-1">
        <ul>
          <li v-for="message in importModal.errorMessages" :key="message">
            {{ message }}
          </li>
        </ul>
      </b-alert>

      <b-form v-if="!isLoadingForm && !isLoading && !isLoadingTable">
        <b-form-group>
          <label for="user">File (.xlsx):</label>
            <b-form-file v-model="importFile" accept=".xlsx"></b-form-file>
        </b-form-group>
        <p class="mt-1"><small><a href="#" @click="sampleExcel()">Download excel file format</a></small></p>
      </b-form>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Batal
        </b-button>
        <b-button size="md" variant="primary" @click="saveImport()" :disabled="importFile === null || isLoadingForm || isLoading || isLoadingTable">
          {{ (!isLoadingForm && !isLoading && !isLoadingTable) ? "Simpan" : "Tunggu..." }}
        </b-button>
      </template>


    </b-modal>

    <b-modal
      id="modal-delete"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDeleteModal"
    >
      <template #modal-header="{}">
        <h5>Hapus {{ pageName }}</h5>
      </template>

      <p>
        Apakah anda yakin untuk menghapus data ini?
      </p>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Batal
        </b-button>
        <b-button size="md" variant="danger" @click="remove()" :disabled="isLoadingDelete">
          {{ (isLoadingDelete) ? "Tunggu..." : "Hapus" }}
        </b-button>
      </template>


    </b-modal>

  </b-card>

</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BFormFile, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner, BBadge, BFormCheckbox, BButtonGroup, BAlert
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as XLSX from 'xlsx';

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    vSelect,
    BCard,
    BForm,
    BButton,
    BSpinner,
    BBadge,
    BFormCheckbox,
    BButtonGroup,
    BFormFile,
    BAlert,
    XLSX
  },
  data() {
    return {
      showToggleVerification: false,
      filterValues: [
        {
          label: "Semua Hak Akses",
          value: "all"
        }
      ],
      filterVerifiedValues: [
        {
          label: "Semua Status Verifikasi",
          value: "all"
        },
        {
          label: "Diverifikasi",
          value: "verified"
        },
        {
          label: "Belum Diverifikasi",
          value: "unverified"
        }
      ],
      userFilter: "all",
      userVerifiedFilter: "all",
      isLoading: false,
      pageLength: 10,
      dir: false,
      columns: [
        {
            label: 'No',
            field: 'row_number'
        },
        {
          label: 'Nama',
          field: 'name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'No. Tel',
          field: 'phone',
        },
        {
          label: 'Address',
          field: 'address',
        },
        {
          label: 'Gereja',
          field: 'church_detail',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Akses',
          field: 'role',
        },
        {
          label: 'Tgl Pembuatan',
          field: 'created_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm',
        },
        {
          label: 'Tgl Perbaharuan',
          field: 'updated_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm',
        },
        {
          label: 'Aksi',
          field: 'action',
        },
      ],
      allRows: [],
      rows: [],
      // searchTerm: '',

      isLoadingTable: false,
      isLoadingExcel: false,
      isLoadingForm: false,
      isLoadingDelete: false,
      formState: "Edit",
      isEdit: false,
      showFormModal: false,
      showDeleteModal: false,
      pageName: "Pengguna",

      currentObject: {
        id: -1,
        name: ''
      },

      importFile: null,

      importModal: {
        successMessage: null,
        errorMessages: null,

      },

      showImportModal: false,
      totalRecords: 0,
      serverParams: {
        search: null,
        role: "all",
        verified_status: "all",
        columnFilters: {},
        sortBy: null,
        sortType: null,
        page: 1, 
        perPage: 10
      }
    }
  },
  computed: {
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onSearchChange() {
      if(this.serverParams.search.length > 0) {
        this.updateParams({search: this.serverParams.search})
      }
      else {
        this.updateParams({search: null})
      }
      
      this.loadItems()
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage})
      this.loadItems()

    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage})
      this.loadItems()
    },

    onSortChange(params) {
      if(params[0].field == "role" || params[0].field == "action") {
        return
      }

      if(this.serverParams.sortBy == params[0].field) {
        if(params[0].type == "asc" && params[0].type == this.serverParams.sortType) {
          this.updateParams({sortType: "desc"})
        }
        else 
        if(params[0].type == "desc" && params[0].type == this.serverParams.sortType){
          this.updateParams({sortType: "asc"})
        }
      }
      else {
        this.updateParams({sortBy: params[0].field})
        this.updateParams({sortType: params[0].type})
      }

      this.loadItems()
    },
    
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems()
    },

    loadItems() {
      this.isLoadingTable = true
      this.fetchData(this.serverParams).then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.allRows = currentData.rows
            this.rows = currentData.rows
            this.totalRecords = currentData.count
          }

          console.log(this.rows)

          this.isLoadingTable = false
     })
    },

    formatDateReverse(value) {
      return moment(String(value), 'YYYY-MM-DD H:mm:s').format('DD-MM-YYYY HH:mm')
    },
    defaultExcelHeaders() {
      return [
        "Nama",
        "Job Title",
        "Job Level",
        "Home Address",
        "Office Address",
        "Office City",
        "Office No. Tel",
        "Mobile No. Tel",
        "Office Email",
        "Private Email",
        "Industry",
        "Date"
      ]
    },
    importExcel() {
      this.importModal = {
        successMessage: null,
        errorMessages: null
      }
      
      this.showImportModal = true

      this.currentObject.id = -1
      this.currentObject.name = ''
    },
    sampleExcel() {

      let excelHeaders = this.defaultExcelHeaders()

      const worksheet = XLSX.utils.json_to_sheet([])

      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, "All Data")
      XLSX.utils.sheet_add_aoa(worksheet, [excelHeaders], { origin: "A1" });

      XLSX.writeFile(workbook, "Example.xlsx", { compression: true });
    },
    submitExcel(defaultData) {
      this.isLoadingForm = true

      this.importModal.errorMessages = []

      this.$http.post('/admin/v1/users_batch', {
        rows: defaultData,
      })
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.importModal.errorMessages = []

            if(data.data.errors !== null) {
              data.data.errors.forEach((error, i) => {
                this.importModal.errorMessages.push(error)
              })
            }

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Sukses`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

            this.showImportModal = false

          }

          this.isLoadingForm = false

          this.loadItems()
      })
    },
    saveImport() {
      this.excelToJSON(this.importFile)
    },
    excelToJSON(file) {
      this.isLoadingForm = true

      let excelHeaders = this.defaultExcelHeaders()

      var reader = new FileReader()

      reader.onload = (e) => {
        var data = e.target.result
        var workbook = XLSX.read(data, {
          type: 'binary'
        })

        workbook.SheetNames.forEach((sheetName) => {
          // Here is your object
          var rows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName], {defval: ""})

          let rowsFormatted = []

          if(rows.length == 0) {
            const errorMessage = "The excel file can't be empty"

            if(this.importModal.errorMessages === null) {
              this.importModal.errorMessages = []
            }

            this.importModal.errorMessages.push(errorMessage)
          }
          else {
            const indices = Object.keys(rows[0])

            rows.forEach((row, i) => {
              let formattedObject = {}

              indices.forEach((currentIndex, k) => {
                let formattedIndex = ''

                if(this.defaultExcelHeaders().includes(currentIndex)) {
                  formattedIndex = currentIndex.toLowerCase()

                  formattedIndex = formattedIndex.replace(/ /g, "_");
                  
                  if(row[currentIndex] == "") {
                    formattedObject[formattedIndex] = null
                  }
                  else {
                    formattedObject[formattedIndex] = row[currentIndex]
                  }

                }
              })

              rowsFormatted.push(formattedObject)

            })

            this.submitExcel(rowsFormatted)
          }
        })
      }

      reader.onerror = (ex) => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Error`,
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: "Error processing excel file",
          },
        })

        this.isLoadingForm = false
      }

      reader.readAsBinaryString(file);
    },
    toggleVerification() {
      this.showToggleVerification = !this.showToggleVerification

      if(!this.showToggleVerification) {
        this.loadItems()
      }
    },
    onVerificationToggle(is_verified, id) {
      const requestData = {
          'verify': is_verified ? 1 : 0
        }

      this.$http.put('/admin/v1/users/' + id + '/verify', requestData)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Sukses`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })
          }

          this.isLoadingForm = false
      })
      
    },
    downloadExcel() {
      this.isLoading = true
      this.isLoadingTable = true
      this.isLoadingExcel = true

      let excelRows = []
      // console.log(this.allRows)

      //download all data
      this.$http.get('/admin/v1/users').then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.allRows = currentData
            //console.log(currentData)
            //console.log(this.allRows)
            this.allRows.map(row => {

                let currentObj = {}

                // let updated_by = null

                // if(row.updated_by !== null) {
                //   updated_by = row.updated_by.name
                // }
                // else 
                // if(row.created_by !== null){
                //   updated_by = row.created_by.name
                // }

                currentObj = {
                  company: (row.profile) ? row.profile.company : '-',
                  brand_name: (row.profile) ? row.profile.brand_name : '-',
                  salutation: (row) ? row.salutation : '-',
                  first_name: (row) ? row.name : '-',
                  last_name: (row) ? row.last_name : '-',
                  position: (row.profile) ? row.profile.position : '-',
                  job_level: (row.profile) ? row.profile.job_level : '-',
                  company_address: (row.profile) ? row.profile.company_address : '-',
                  company_city: (row.profile && row.profile.company_city) ? row.profile.company_city.name : '-',
                  company_state: (row.profile  && row.profile.company_city && row.profile.company_city.state) ? row.profile.company_city.state.name : '-',
                  company_country: (row.profile  && row.profile.company_city && row.profile.company_city.state && row.profile.company_city.state.country) ? row.profile.company_city.state.country.name : '-',
                  home_address: (row.profile) ? row.profile.home_address : '-',
                  company_phone: (row.profile) ? row.profile.company_phone : '-',
                  phone: (row.profile) ? row.profile.phone : '-',
                  company_email: (row.profile) ? row.profile.company_email : '-',
                  email:  (row) ? row.email : '-',
                  industry: (row.profile) ? row.profile.industry : '-',
                  // personal_email: (row.profile) ? row.profile.personal_email : '-',
                  company_size: (row.profile) ? row.profile.company_size : '-',
                  registered_at: this.formatDateReverse(row.created_at),
                  verified_at: (row.email_verified_at) ? this.formatDateReverse(row.email_verified_at) : '-',
                  last_updated_at: (row.updated_at) ? this.formatDateReverse(row.updated_at) : this.formatDateReverse(row.created_at),
                  // last_updated_by: (row.updated_by) ? row.updated_by.name : row.created_by.name, 
                }

              excelRows.push(currentObj)
            })

            let excelHeaders = []

            excelRows.forEach((row, index) => {
              for(const [objIndex, [key, value]] of Object.entries(Object.entries(row))) {

                if(index == 0) {
                  const words = key.split("_");

                  let colName = []

                  words.forEach((word) => {
                    colName.push(word[0].toUpperCase() + word.substring(1))
                  })

                  excelHeaders.push(colName.join(" "))
                }

                if(value == '' || value === null) {
                  excelRows[index][key] = '-'
                }
              }
            })



            const worksheet = XLSX.utils.json_to_sheet(excelRows)

            const workbook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workbook, worksheet, "All Data")
            XLSX.utils.sheet_add_aoa(worksheet, [excelHeaders], { origin: "A1" });

            XLSX.writeFile(workbook, this.pageName + "_" + Date.now() + ".xlsx", { compression: true });

          }

          // console.log(this.allRows)
          this.isLoading = false
          this.isLoadingTable = false
          this.isLoadingExcel = false
      })
      
      
    },
    onFilterChange(value) {
      this.updateParams({role: value})
      this.loadItems()
    },
    onVerifiedFilterChange(value) {
      this.updateParams({verified_status: value})
      this.loadItems()
    },
    fetchRoles() {
      this.$http.get('/admin/v1/roles')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            currentData.forEach((item, index) => {
              this.filterValues.push({
                label: item.name,
                value: item.name
              })
            })
            
          }
      })
    },
    statusVariant(emailVerifiedAt) {
      return emailVerifiedAt ? 'light-success' : 'light-danger'
    },
    roleVariant(roles) {
      if(roles === undefined || roles === null) {
        return 'light-info'
      }

      if(roles.length > 0) {
        if(roles[0].name == "Super Admin") {
          return 'light-primary'
        }
        else 
        if(roles[0].name == "Admin") {
          return 'light-primary'
        }
        else
        if(roles[0].name == "User") {
          return 'light-warning'
        }
        else {
          return 'light-info'
        }
      }
      else {
        return 'light-info'
      }
    },
    add() {
      this.$router.push({ name: 'main-users-add' })
    },
    viewPage(id) {

      this.$router.push('/users/' + id + '/view')

    },
    editPage(id) {

      this.$router.push('/users/' + id + '/edit')

    },
    removeModal(id) {      
      this.showDeleteModal = true

      this.currentObject.id = id
    },
    remove() {
      this.isLoadingDelete = true

      const id = this.currentObject.id

      this.$http.delete('/admin/v1/users/' + id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Sukses`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

            this.showDeleteModal = false
          }

          this.isLoadingDelete = false

          this.loadItems()
      })
    },
    fetchData(params) {
      this.isLoadingTable = true

      return this.$http.post('/admin/v1/users_filtered', params)
    }
  },
  created() {
    this.fetchRoles()
    this.loadItems()
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vgt-responsive {
  min-height: 180px;
}

.user-filter .v-select.vs--single.vs--searchable {
  width: 260px;
}
</style>